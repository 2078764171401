.input {
  border-radius: 15px !important;
  padding: 10px 15px !important;
}

.del:hover {
  cursor: pointer !important;
  color: black !important;
}
.preview {
  display: block;
}

.print {
  display: none;
}

@media print {
  .preview {
    display: none !important;
  }

  .print {
    display: block !important;
  }
}
