.chart_icon {
  color: var(--primary);
  font-size: 20px;
  transition: 0.3s all ease-in-out;
  margin: 0 10px;
}

.logo {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100px;
  border-radius: 50%;
}
.logo2 {
  position: absolute;
  top: 0px;
  right: 0;
  width: 100px;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
}

.chart_icon:hover {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.table {
  font-size: 14px !important;
  overflow: scroll;
}

.std_info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr !important;
  gap: 10px;
}
.std_overview {
  display: grid;
  grid-template-columns: 1fr 2fr !important;
  /* grid-template-columns: 220px 1fr 1fr !important; */
  gap: 10px;
}
.std_overview_inner {
  display: grid;
  grid-template-columns: 1fr 1fr !important;
  /* grid-template-columns: 220px 1fr 1fr !important; */
  gap: 10px;
}
.wrapper {
  font-size: 12px !important;
}

.logo {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100px;
  border-radius: 50%;
}
.logo2 {
  position: absolute;
  top: 0px;
  right: 0;
  width: 100px;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
}
.info_heading {
  margin-right: 12px;
  white-space: nowrap !important;
}
.info_text {
  white-space: nowrap;
}

@page {
  margin: 20px 20px 20px 20px !important;
}

@media print {
  .table {
    font-size: 12px !important;
    overflow: scroll;
  }
}
